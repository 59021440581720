<script lang="ts">
  import FloatingFooter from "../../utils/FloatingFooter.svelte";
  import RadioGroup from "../../utils/RadioGroup.svelte";
  import { type Component } from "svelte";

  let {
    answer,
    back,
    cancel,
    clientName,
    nextSteps,
  }: {
    answer: ({ nextStep, status }) => void;
    back: () => void;
    cancel: () => void;
    clientName: { [key: string]: string };
    nextSteps: { [key: string]: Component };
  } = $props();

  let selectedOption = $state(null);

  function handleContinue() {
    if (selectedOption === "unchanged") {
      answer({ nextStep: nextSteps.next, status: selectedOption });
    } else if (selectedOption === "changed") {
      answer({ nextStep: nextSteps.name, status: selectedOption });
    } else if (selectedOption === "other_person") {
      answer({ nextStep: nextSteps.other, status: selectedOption });
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      handleContinue();
    }
  }
</script>

<div
  class="h-full flex flex-col gap-4"
  onkeydown={handleKeyDown}
  role="link"
  tabindex="0"
>
  <div>
    Did you purchase this vehicle under the name
    <b>{clientName.firstName} {clientName.lastName}</b>?
  </div>

  <RadioGroup
    name="client_name[status]"
    options={[
      ["Yes", "unchanged"],
      ["No, I used a different name when I purchased this vehicle", "changed"],
      ["No, someone else's name is on the finance agreement", "other_person"],
    ]}
    bind:group={selectedOption}
  />
</div>

<FloatingFooter>
  {#if back}
    <button type="button" class="btn btn-ghost" onclick={back}>Back</button>
  {:else}
    <button type="button" class="btn btn-ghost" onclick={cancel}>Cancel</button>
  {/if}
  <button
    type="button"
    class="btn btn-primary"
    onclick={handleContinue}
    disabled={!selectedOption}>Continue</button
  >
</FloatingFooter>
