<script lang="ts">
  import WhatsApp from "~icons/ph/whatsapp-logo";
  import Chat from "~icons/ph/chat-text";
  import Email from "~icons/ph/envelope-simple";
  import Copy from "~icons/ph/copy";
  import { fade } from "svelte/transition";
  import revert from "../../stores/revert";
  import FloatingFooter from "../utils/FloatingFooter.svelte";

  let {
    vehicleInformation,
    cancel
  }: {
    vehicleInformation: string;
    cancel: ()=>void;
  } = $props();

  const shareUrl = "https://claims.johnsonlawgroup.co.uk/finance.php?oid=1823";
  const message = `Start a PCP claim with Johnson Law Group for this vehicle: ${vehicleInformation}`;
  const fullMessage = `${message} ${shareUrl}`.trim();
  const messageParam = encodeURIComponent(message);

  let copied = revert(false, 3_000);
  async function copyMessage() {
    try {
      await navigator.clipboard.writeText(fullMessage);
      $copied = true;
    } catch (error) {
      console.error(error.message);
    }
  }
</script>

<div class="h-full flex flex-col gap-4">
  <div>
    We have received your request. Our team will contact you to confirm when
    your case is closed.
  </div>
  <br />
  <div class="font-bold">Share with someone how to start a PCP claim:</div>
  <div
    class="rounded-lg border border-solid border-base-200 shadow-inner mb-2 max-w-xs mx-auto sm:max-w-full"
  >
    <div
      class="mx-1 px-4 flex flex-col justify-end min-h-44 overflow-hidden relative"
    >
      <output
        class="bubble py-2 px-3 text-base block bg-blue-600 text-white rounded-2xl my-5 whitespace-pre-wrap"
        >{message}<span
          class="text-nowrap inline-block align-top underline overflow-x-hidden w-[33ch] max-w-full text-ellipsis"
          >{shareUrl}</span
        ></output
      >
      <div class="flex items-center gap-1 absolute right-1 top-2">
        {#if $copied}
          <div
            class="badge badge-sm badge-outline border border-solid bg-white"
            transition:fade
          >
            Copied
          </div>
        {/if}
        <button
          type="button"
          class="btn btn-sm btn-circle"
          onclick={copyMessage}><Copy /></button
        >
      </div>
    </div>
  </div>
  <div class="flex justify-center gap-4">
    <div class="tooltip" data-tip="Send with WhatsApp">
      <a
        href="https://wa.me?text={messageParam}"
        target="_blank"
        rel="noopener"
        class="btn shadow btn-circle bg-green-600 hover:bg-green-500 text-white hover:text-white"
        ><WhatsApp class="w-7 h-7" /><span class="sr-only"
          >Send with WhatsApp</span
        ></a
      >
    </div>
    <div class="tooltip" data-tip="Send as text message">
      <a href="sms:?body={messageParam}" class="btn shadow btn-circle btn-blue"
        ><Chat class="w-7 h-7" /><span class="sr-only"
          >Send as text message</span
        ></a
      >
    </div>
    <div class="tooltip" data-tip="Send as email">
      <a
        href="mailto:?subject={encodeURIComponent(
          'Starting a PCP claim with Johnson Law Group',
        )}&amp;body={messageParam}"
        class="btn shadow btn-circle border border-solid border-black bg-base-200 hover:bg-base-100 hover:border hover:border-gray-700"
        ><Email class="w-6 h-6" /><span class="sr-only">Send as email</span></a
      >
    </div>
  </div>
</div>

<FloatingFooter justify="justify-end">
  <button type="button" class="btn btn-primary self" onclick={cancel}>Close</button>
</FloatingFooter>

<style lang="postcss">
  .btn-blue {
    @apply bg-blue-600 hover:bg-blue-500 text-white hover:text-white;
  }

  .bubble {
    @apply relative;
    /* tail styles substantially stolen from https://samuelkraft.com/blog/ios-chat-bubbles-css */
    &:before {
      width: 20px;
      right: -7px;
      @apply bg-blue-600;
      border-bottom-left-radius: 16px 14px;
    }

    &:after {
      width: 26px;
      @apply bg-white;
      right: -26px;
      border-bottom-left-radius: 10px;
    }

    &:before,
    &:after {
      position: absolute;
      bottom: 0;
      height: 25px; /* height of our bubble "tail" - should match the border-radius above */
      content: "";
    }
  }
</style>
