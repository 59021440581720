<script lang="ts">
  import FloatingFooter from "../utils/FloatingFooter.svelte";
  import RadioGroup from "../utils/RadioGroup.svelte";
  import { type Component } from "svelte";

  let {
    answer,
    cancel,
    financeCompany,
    taskDueDateLocalized,
    nextSteps,
  }: {
    answer: ({ nextStep, status }) => void;
    cancel: () => void;
    financeCompany: string;
    taskDueDateLocalized: string;
    nextSteps: { [key: string]: Component };
  } = $props();

  let selectedOption = $state(null);

  function handleContinue() {
    if (selectedOption === "yes") {
      answer({ nextStep: nextSteps.next, status: "upload" });
    } else if (selectedOption === "no") {
      answer({ nextStep: nextSteps.questions, status: "no upload" });
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      handleContinue();
    }
  }
</script>

<div
  class="h-full flex flex-col gap-4"
  onkeydown={handleKeyDown}
  role="link"
  tabindex="0"
>
  <div>
    <b>{financeCompany}</b> has informed us that they could not find records confirming
    that you were their client.
  </div>
  <div>
    To proceed with your claim, please upload a copy of your finance agreement
    by <b>{taskDueDateLocalized}</b>. We cannot move forward without this
    information.
  </div>

  <div class="flex flex-col gap-4">
    <RadioGroup
      name="finance_agreement[upload]"
      options={[
        ["I will upload my finance agreement", "yes"],
        ["I will answer a few questions instead", "no"]
      ]}
      bind:group={selectedOption}
    />
  </div>

  <div>Estimated time: 2 minutes</div>
</div>

<FloatingFooter>
  <button type="button" class="btn btn-ghost" onclick={cancel}>Cancel</button>
  <button
    type="button"
    class="btn btn-primary"
    onclick={handleContinue}
    disabled={!selectedOption}>Continue</button
  >
</FloatingFooter>
