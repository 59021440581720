<script lang="ts">
  import Form from "../components/Form.svelte";
  import Initial from "./RespondToFinanceCompanyTask/Initial.svelte";
  import UploadFinanceAgreement from "./RespondToFinanceCompanyTask/UploadFinanceAgreement.svelte";
  import ConfirmFinanceDate from "./RespondToFinanceCompanyTask/ConfirmFinanceDate.svelte";
  import ConfirmClientName from "./RespondToFinanceCompanyTask/ClientName/ConfirmClientName.svelte";
  import ChangeClientName from "./RespondToFinanceCompanyTask/ClientName/ChangeClientName.svelte";
  import ClientIsOtherPerson from "./RespondToFinanceCompanyTask/ClientName/ClientIsOtherPerson.svelte";
  import ConfirmDateOfBirth from "./RespondToFinanceCompanyTask/ConfirmDateOfBirth.svelte";
  import ConfirmClientAddress from "./RespondToFinanceCompanyTask/ClientAddress/ConfirmClientAddress.svelte";
  import ChangeClientAddress from "./RespondToFinanceCompanyTask/ClientAddress/ChangeClientAddress.svelte";
  import ConfirmFinanceCompany from "./RespondToFinanceCompanyTask/FinanceCompany/ConfirmFinanceCompany.svelte";
  import ChangeFinanceCompany from "./RespondToFinanceCompanyTask/FinanceCompany/ChangeFinanceCompany.svelte";
  import VehicleNotFinanced from "./RespondToFinanceCompanyTask/FinanceCompany/VehicleNotFinanced.svelte";
  import CloseCaseConfirmation from "./RespondToFinanceCompanyTask/CloseCaseConfirmation.svelte";
  import TaskConfirmation from "./RespondToFinanceCompanyTask/TaskConfirmation.svelte";
  import {localizeDate} from "../lib/date";
  import type { Component } from "svelte";

  let {
    action,
    createUploadSessionUrl,
    financeAgreementUploadAllowedExtensions,
    financeCompany,
    financeCompanyOptions,
    taskDueDate,
    financeAgreementStartDate,
    clientName,
    clientNameUploadAllowedExtensions,
    dateOfBirth,
    clientAddress,
    displayCloseCaseConfirmation,
    vehicleInformation,
    errors,
    elementsToCorrect,
  }: {
    action: string;
    createUploadSessionUrl: string;
    financeAgreementUploadAllowedExtensions: string[];
    financeCompany: string;
    financeCompanyOptions: string[];
    taskDueDate: string;
    financeAgreementStartDate: string | null;
    clientName: { [key: string]: string };
    clientNameUploadAllowedExtensions: string[];
    dateOfBirth: string;
    clientAddress: { [key: string]: string };
    displayCloseCaseConfirmation?: boolean;
    vehicleInformation?: string;
    errors: { [key: string]: string } | null;
    elementsToCorrect: string | null;
  } = $props();

  // For now, if multiple elements are passed (e.g. "Date of Birth;Address Verification"),
  // correctOneElement won't match, so we will show all steps.
  const correctOneElement : Component | undefined = ({
    "Full Name / Maiden Name": ConfirmClientName,
    "Date of Birth": ConfirmDateOfBirth,
    "Address Verification": ConfirmClientAddress,
  }[elementsToCorrect]);
  let currentStep = $state<Component>(correctOneElement || Initial);
  if (displayCloseCaseConfirmation) currentStep = CloseCaseConfirmation; // TODO: Does this update properly when the prop changes or do we need to do it in $effect?

  let answers = $state([]);
  let allInformationIsUnchanged = $derived(answers.every((answer) => answer.status !== "changed"));
  let financeAgreementStartDateFromClient = $state(financeAgreementStartDate);
  let form = $state<HTMLFormElement>();
  let modalBox = $derived(form?.closest(".modal-box"));
  let dialog = $derived(form?.closest("dialog"));

  function answer({ nextStep, status }) {
    answers = [...answers, { step: currentStep, status: status }];
    currentStep = nextStep;
    modalBox.scrollTop = 0;
  }

  function back() {
    currentStep = answers.pop().step;
    modalBox.scrollTop = 0;
  }

  function cancel(){
    dialog.close();
  }

  function updateFinanceAgreementStartDate(newDate) {
    financeAgreementStartDateFromClient = newDate;
  }

  // Prevent the form from being submitted (by pressing Enter key for example) when not on the final step
  function handleSubmit(event: Event) {
    if (
      ![
        UploadFinanceAgreement,
        ClientIsOtherPerson,
        VehicleNotFinanced,
        TaskConfirmation,
      ].includes(currentStep)
    ) {
      event.preventDefault();
    }
  }

  function makeStep(StepComponent, propsFn = null) {
    return [
      StepComponent,
      ()=>{
        if(!propsFn) return {};
        let nextStepProp; 
        const [props, nextSteps] = propsFn();
        // A single component
        if(typeof nextSteps === "function") nextStepProp = {nextStep: nextSteps};
        // A hash of two or more components
        else if(nextSteps) nextStepProp = {nextSteps};
        // No next step(s)
        else nextStepProp = {};
        return Object.assign(props, nextStepProp);
      }
    ];
  }
  const steps = [
    makeStep(Initial, ()=>[
      {financeCompany, taskDueDateLocalized: localizeDate(taskDueDate)},
      {
        next: UploadFinanceAgreement,
        questions: ConfirmFinanceDate,
      }
    ]),
    makeStep(UploadFinanceAgreement, ()=>[
      {createUploadSessionUrl, financeAgreementUploadAllowedExtensions, required: currentStep === UploadFinanceAgreement, errors}
    ]),
    makeStep(ConfirmFinanceDate, ()=>[
      {updateFinanceAgreementStartDate, financeAgreementStartDate},
      ConfirmClientName
    ]),
    makeStep(ConfirmClientName, ()=>[
      {clientName},
      {
        next: correctOneElement ? TaskConfirmation : ConfirmDateOfBirth,
        name: ChangeClientName,
        other: ClientIsOtherPerson
      }
    ]),
    makeStep(ChangeClientName, ()=>[
      {clientName, financeAgreementStartDate: localizeDate(financeAgreementStartDateFromClient), createUploadSessionUrl, clientNameUploadAllowedExtensions},
      correctOneElement ? TaskConfirmation : ConfirmDateOfBirth
    ]),
    makeStep(ClientIsOtherPerson),
    makeStep(ConfirmDateOfBirth, ()=>[
      {dateOfBirth, dateOfBirthLocalized: localizeDate(dateOfBirth)},
      correctOneElement ? TaskConfirmation : ConfirmClientAddress
    ]),
    makeStep(ConfirmClientAddress, ()=>[
      {clientAddress, financeAgreementStartDateLocalized: localizeDate(financeAgreementStartDateFromClient)},
      {
        next: correctOneElement ? TaskConfirmation : ConfirmFinanceCompany,
        change: ChangeClientAddress
      }
    ]),
    makeStep(ChangeClientAddress, ()=>[
      {clientAddress, financeAgreementStartDateLocalized: localizeDate(financeAgreementStartDateFromClient)},
      correctOneElement ? TaskConfirmation : ConfirmFinanceCompany
    ]),
    makeStep(ConfirmFinanceCompany, ()=>[
      {financeCompany},
      {
        next: TaskConfirmation,
        change: ChangeFinanceCompany,
        close: VehicleNotFinanced
      }
    ]),
    makeStep(ChangeFinanceCompany, ()=>[
      {financeCompany, financeCompanyOptions},
      TaskConfirmation
    ]),
    makeStep(VehicleNotFinanced),
    makeStep(TaskConfirmation, ()=>[
      {allInformationIsUnchanged}
    ]),
    makeStep(CloseCaseConfirmation, ()=>[
      {vehicleInformation}
    ]),
  ]
</script>

<Form
  method="patch"
  {action}
  class="grid place-items-stretch text-base"
  onsubmit={handleSubmit}
  bind:form={form}
>
  {#each steps as [StepComponent, props] (StepComponent)}    
    <div
      class:invisible={currentStep !== StepComponent}
      class="row-span-full col-span-full"
    >
      <StepComponent {answer} back={answers.length > 0 ? back : null} {cancel} {...props()} />
    </div>
  {/each}
  {#if correctOneElement}
    <input type="hidden" name="finance_agreement[upload]" value="no">
  {/if}
</Form>
