<script lang="ts">
  import FloatingFooter from "../../utils/FloatingFooter.svelte";
  import RadioGroup from "../../utils/RadioGroup.svelte";
  import { type Component } from "svelte";
  
  let {
    answer,
    back,
    financeCompany,
    nextSteps,
  }: {
    answer: ({ nextStep, status }) => void;
    back: () => void;
    financeCompany: string;
    nextSteps: { [key: string]: Component };
  } = $props();

  let selectedOption = $state(null);
  let nextStep = $derived({
    unchanged: nextSteps.next,
    changed: nextSteps.change,
    not_financed: nextSteps.close
  }[selectedOption])

  function handleContinue() {
    if(!nextStep) return;
    answer({ nextStep, status: selectedOption });
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      handleContinue();
    }
  }
</script>

<div
  class="h-full flex flex-col gap-4"
  onkeydown={handleKeyDown}
  role="link"
  tabindex="0"
>
  <div>
    Did you finance this vehicle with <b>{financeCompany}</b>?
  </div>

  <RadioGroup
    name="finance_company[status]"
    options={[
      [`Yes, ${financeCompany}`, "unchanged"],
      ["No, a different finance company", "changed"],
      ["No financing: I purchased it outright", "not_financed"]
    ]}
    bind:group={selectedOption}
  />

  <div class="divider my-0"></div>
  <div class="prose text-black">
    <p class="mb-0">
      If you are not sure who your finance company is, you can:
    </p>
    <ol>
      <li class="list-decimal">
        Check with your bank to see who your monthly payments were sent to, or
      </li>
      <li class="list-decimal">
        Check your credit report for free with any of the
        <a href="https://ico.org.uk/for-the-public/credit/" target="_blank"
          >major credit referencing agencies</a
        >
        and identify the lender / finance company
      </li>
    </ol>
  </div>
</div>

<FloatingFooter>
  <button type="button" class="btn btn-ghost" onclick={back}>Back</button>
  <button
    type="button"
    class="btn btn-primary"
    onclick={handleContinue}
    disabled={!selectedOption}>Continue</button
  >
</FloatingFooter>
