<script lang="ts">
  import SharepointFileInput from "../../components/SharepointFileInput.svelte";
  import FloatingFooter from "../utils/FloatingFooter.svelte";

  let {
    back,
    createUploadSessionUrl,
    financeAgreementUploadAllowedExtensions,
    required,
    errors
  }: {
    back: () => void;
    createUploadSessionUrl: string;
    financeAgreementUploadAllowedExtensions: string[];
    required: boolean;
    errors: { [key: string]: string } | null;
  } = $props();

  let fileInput = $state<SharepointFileInput>();

  function handleBack() {
    fileInput?.cancelAll();
    back();
  }
</script>

<div class="h-full flex flex-col gap-1">
  <div class="inline-block font-medium">
    Upload a copy of your finance agreement
  </div>
  <div class="form-control w-full">
    <SharepointFileInput
      bind:this={fileInput}
      name="finance_agreement_document"
      accept={financeAgreementUploadAllowedExtensions}
      {createUploadSessionUrl}
      multiple={true}
      required={required}
    />
    {#if errors?.finance_agreement_document}<div class="text-error">{errors.finance_agreement_document}</div>{/if}
  </div>
</div>

<FloatingFooter>
  <button type="button" class="btn btn-ghost" onclick={handleBack}>Back</button>
  <button type="submit" class="btn btn-primary">Done</button>
</FloatingFooter>
