<script lang="ts">
  import type { Component } from "svelte";
  import { domId } from "../../lib/dom";
  import FloatingFooter from "../utils/FloatingFooter.svelte";
  import RadioGroup from "../utils/RadioGroup.svelte";

  let {
    answer,
    back,
    cancel,
    dateOfBirth,
    dateOfBirthLocalized,
    nextStep,
  }: {
    answer: ({ nextStep, status }) => void;
    back: () => void | null;
    cancel: () => void;
    dateOfBirth: string;
    dateOfBirthLocalized: string;
    nextStep: Component;
  } = $props();

  let dateOfBirthInput = $state(dateOfBirth);
  let warning = $state(null);
  let error = $state(null);
  let selectedOption = $state(dateOfBirth === null ? "changed" : null);

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      handleContinue();
    }
  }

  function handleContinue() {
    verifyDateInput();
    if (allowContinue()) {
      error = null;
      answer({ nextStep, status: selectedOption });
    }
  }

  function allowContinue() {
    return (
      selectedOption === "unchanged" ||
      (selectedOption === "changed" && dateOfBirthInput && !error)
    );
  }

  function verifyDateInput() {
    if (selectedOption === "changed") {
      const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD format to compare with the date input
      error = null;
      warning = null;
      if (dateOfBirth === dateOfBirthInput) {
        error =
          "Please enter a different date from the original one you provided";
      } else if (dateOfBirthInput > today) {
        error = "Are you sure? This date is in the future";
      }
    }
  }

  const dateOfBirthId = domId("date-of-birth");
</script>

<div
  class="h-full flex flex-col gap-4"
  onkeydown={handleKeyDown}
  role="link"
  tabindex="0"
>
  <div class:hidden={dateOfBirth === null} class="flex flex-col gap-4">
    <div>
      Were you born on <b>{dateOfBirthLocalized}</b>?
    </div>

    <RadioGroup
      name="date_of_birth[status]"
      options={[
        ["Yes", "unchanged"],
        ["No", "changed"],
      ]}
      bind:group={selectedOption}
    />
  </div>

  <div class:hidden={selectedOption !== "changed"} class="flex flex-col gap-1">
    <label for={dateOfBirthId} class="inline-block font-medium">
      What is your date of birth?
    </label>
    <input
      id={dateOfBirthId}
      type="date"
      name="date_of_birth[date]"
      class="input input-bordered w-full max-w-xs"
      disabled={selectedOption !== "changed"}
      bind:value={dateOfBirthInput}
    />
  </div>
  {#if error}
    <div class="bg-error-200 text-center rounded p-2">{error}</div>
  {/if}
</div>

<FloatingFooter>
  {#if back}
    <button type="button" class="btn btn-ghost" onclick={back}>Back</button>
  {:else}
    <button type="button" class="btn btn-ghost" onclick={cancel}>Cancel</button>
  {/if}
  <button
    type="button"
    class="btn btn-primary"
    onclick={handleContinue}
    disabled={!selectedOption}>Continue</button
  >
</FloatingFooter>
