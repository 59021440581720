<script lang="ts">
  import { domId } from "../../../lib/dom";
  import FloatingFooter from "../../utils/FloatingFooter.svelte";
  import { type Component } from "svelte";

  let {
    answer,
    back,
    financeCompany,
    financeCompanyOptions,
    nextStep,
  }: {
    answer: ({ nextStep }) => void;
    back: () => void;
    financeCompany: string;
    financeCompanyOptions: string[];
    nextStep: Component;
  } = $props();

  let financeCompanyInput = $state("");
  let error = $state(null);

  function handleContinue() {
    confirmNameInput();
    if (!error) {
      answer({nextStep});
    }
  }

  function confirmNameInput() {
    error = null;
    if (financeCompanyInput === "") {
      error = "Finance company name is required";
    }
    if (financeCompanyInput === financeCompany) {
      error = "Please enter a different finance company name from the original one you provided";
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      handleContinue();
    }
  }

  const financeCompanyId = domId("finance-company");
  const financeCompanyOptionsId = domId("finance-company-options");
</script>

<div
  class="h-full flex flex-col gap-4"
  onkeydown={handleKeyDown}
  role="link"
  tabindex="0"
>
  <div>What was the name of the finance company?</div>
  <div class="flex flex-col gap-1">
    <input
      id={financeCompanyId}
      type="text"
      list={financeCompanyOptionsId}
      name="finance_company[name]"
      class="input input-bordered w-full"
      bind:value={financeCompanyInput}
    />
    <span class="label-text">Type to search or to enter a new name</span>
  </div>

  <div><b>Previous answer</b>: {financeCompany}</div>
  {#if error}
    <div class="bg-error-200 text-center rounded p-2">{error}</div>
  {/if}

  <div class="divider my-0"></div>
  <div class="prose text-black">
    <p class="mb-0">
      If you are not sure who your finance company is, you can:
    </p>
    <ol>
      <li class="list-decimal">
        Check with your bank to see who your monthly payments were sent to, or
      </li>
      <li class="list-decimal">
        Check your credit report for free with any of the
        <a href="https://ico.org.uk/for-the-public/credit/" target="_blank">major credit referencing agencies</a>
        and identify the lender / finance company
      </li>
    </ol>
  </div>
</div>

<datalist id={financeCompanyOptionsId}>
  {#each financeCompanyOptions as financeCompanyOption}
    <option value={financeCompanyOption}></option>
  {/each}
</datalist>

<FloatingFooter>
  <button type="button" class="btn btn-ghost" onclick={back}>Back</button>
  <button type="button" class="btn btn-primary" onclick={handleContinue}>Continue</button>
</FloatingFooter>
