<script lang="ts">
  import type { Snippet } from 'svelte';
  import { csrfToken } from "../lib/railsUtil.svelte";
  let {
    action = "",
    method: logicalMethod = "get",
    form = $bindable(),
    children,
    ...props
  } : {
    action : string;
    method : "get" | "post" | "patch" | "put" | "delete";
    form? : HTMLFormElement;
    children? : Snippet;
    props? : {[key: string]: any};
  } = $props()
  let [method, railsMethod] = ["get", "post"].includes(logicalMethod.toLowerCase()) ? [logicalMethod, null] : ["post", logicalMethod]
</script>
<form bind:this={form} {action} method={method as "get" | "post"} {...props}>
  {#if railsMethod}
    <input type="hidden" autocomplete="off" name="_method" value={railsMethod}>
  {/if}
  {@render children?.()}
  {#if method.toLowerCase() !== "get"}
    <input type="hidden" autocomplete="off" {...csrfToken()}>
  {/if}
</form>
