<script lang="ts">
  import FloatingFooter from "../../utils/FloatingFooter.svelte";
  import RadioGroup from "../../utils/RadioGroup.svelte";
  import { type Component } from "svelte";
  
  let {
    answer,
    back,
    cancel,
    clientAddress,
    financeAgreementStartDateLocalized,
    nextSteps,
  }: {
    answer: ({ nextStep, status }) => void;
    back: () => void | null;
    cancel: () => void;
    clientAddress: { [key: string]: string };
    financeAgreementStartDateLocalized: string;
    nextSteps: { [key: string]: Component };
  } = $props();

  let selectedOption = $state(null);

  function handleContinue() {
    if (selectedOption === "unchanged") {
      answer({ nextStep: nextSteps.next, status: selectedOption })
    } else if (selectedOption === "changed") {
      answer({ nextStep: nextSteps.change, status: selectedOption });
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      handleContinue();
    }
  }
</script>

<div
  class="h-full flex flex-col gap-4"
  onkeydown={handleKeyDown}
  role="link"
  tabindex="0"
>
  <div>
    Did you live at the following address on
    <b>{financeAgreementStartDateLocalized}</b>?
  </div>

  <div class="font-medium flex flex-col gap-1">
    <div>{clientAddress.street}</div>
    <div>
      {clientAddress.city}
      <span class="whitespace-nowrap">{clientAddress.zip}</span>
    </div>
  </div>

  <RadioGroup
    name="client_address[status]"
    options={[
      ["Yes", "unchanged"],
      ["No, my address was different back then", "changed"],
    ]}
    bind:group={selectedOption}
  />
</div>

<FloatingFooter>
  {#if back}
    <button type="button" class="btn btn-ghost" onclick={back}>Back</button>
  {:else}
    <button type="button" class="btn btn-ghost" onclick={cancel}>Cancel</button>
  {/if}
  <button
    type="button"
    class="btn btn-primary"
    onclick={handleContinue}
    disabled={!selectedOption}>Continue</button
  >
</FloatingFooter>

