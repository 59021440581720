<script lang="ts">
  import { domId } from "../../../lib/dom";
  import SharepointFileInput from "../../../components/SharepointFileInput.svelte";
  import FloatingFooter from "../../utils/FloatingFooter.svelte";
  import { type Component } from "svelte";

  let {
    answer,
    back,
    clientName,
    financeAgreementStartDateLocalized,
    createUploadSessionUrl,
    clientNameUploadAllowedExtensions,
    nextStep,
  }: {
    answer: ({ nextStep }) => void;
    back: () => void;
    clientName: { [key: string]: string };
    financeAgreementStartDateLocalized: string;
    createUploadSessionUrl: string;
    clientNameUploadAllowedExtensions: string[];
    nextStep: Component;
  } = $props();

  let fileInput = $state<SharepointFileInput>();
  let firstNameInput = $state(clientName.firstName);
  let lastNameInput = $state(clientName.lastName);
  let errors = $state([]);

  function handleBack() {
    fileInput?.cancelAll();
    back();
  }

  function handleContinue() {
    confirmNameInput();
    if (errors.length === 0 && allowContinue()) {
      answer({ nextStep });
    }
  }

  function allowContinue() {
    if (fileInput?.hasFiles) {
      return fileInput?.uploadComplete();
    }

    return true;
  }

  function confirmNameInput() {
    errors = [];
    if (firstNameInput === "") {
      errors.push("First name is required");
    }
    if (lastNameInput === "") {
      errors.push("Last name is required");
    }
    if (
      clientName.firstName === firstNameInput &&
      clientName.lastName === lastNameInput
    ) {
      errors.push(
        "Please enter a different name from the original one you provided",
      );
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      handleContinue();
    }
  }

  $effect(() => {
    allowContinue();
  });

  const firstNameId = domId("first-name");
  const lastNameId = domId("last-name");
</script>

<div
  class="h-full flex flex-col gap-4"
  onkeydown={handleKeyDown}
  role="link"
  tabindex="0"
>
  <div>
    What name did you use to purchase this vehicle on
    <b>{financeAgreementStartDateLocalized}</b>?
  </div>

  <div class="flex flex-col gap-1">
    <label for={firstNameId} class="inline-block font-medium">
      First name
    </label>
    <input
      id={firstNameId}
      type="text"
      name="client_name[first_name]"
      class="input input-bordered w-full"
      bind:value={firstNameInput}
      required
    />
  </div>

  <div class="flex flex-col gap-1">
    <label for={lastNameId} class="inline-block font-medium"> Last name </label>
    <input
      id={lastNameId}
      type="text"
      name="client_name[last_name]"
      class="input input-bordered w-full"
      bind:value={lastNameInput}
      required
    />
  </div>

  {#if errors.length > 0}
    <div class="bg-error-200 text-center rounded p-2">{errors.join(". ")}</div>
  {/if}

  <div class="flex flex-col gap-1">
    <div class="inline-block font-medium">
      Upload a document with your previous name (optional)
    </div>
    <div class="form-control w-full">
      <SharepointFileInput
        bind:this={fileInput}
        name={"client_name_document"}
        {createUploadSessionUrl}
        accept={clientNameUploadAllowedExtensions}
      />
    </div>
  </div>
</div>

<FloatingFooter>
  <button type="button" class="btn btn-ghost" onclick={back}>Back</button>
  <button
    type="button"
    class="btn btn-primary"
    onclick={handleContinue}
    disabled={!allowContinue()}>Continue</button
  >
</FloatingFooter>
