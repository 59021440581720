<script lang="ts">
  import { domId } from "../../lib/dom";
  import FloatingFooter from "../utils/FloatingFooter.svelte";
  import RadioGroup from "../utils/RadioGroup.svelte";
  import {localizeDate} from "../../lib/date";
  import { type Component } from "svelte";

  let {
    answer,
    back,
    updateFinanceAgreementStartDate,
    financeAgreementStartDate,
    nextStep,
  }: {
    answer: ({ nextStep, status }) => void;
    back: () => void;
    updateFinanceAgreementStartDate: (startDateInput: string) => void;
    financeAgreementStartDate: string;
    nextStep: Component;
  } = $props();

  let startDateInput = $state(financeAgreementStartDate);
  let warning = $state(null);
  let error = $state(null);
  let selectedOption = $state(financeAgreementStartDate === null ? "changed" : null);

  function handleContinue() {
    verifyDateInput();
    if (allowContinue()) {
      error = null;
      selectedOption === "changed"
        ? updateFinanceAgreementStartDate(startDateInput)
        : updateFinanceAgreementStartDate(financeAgreementStartDate); // In case startDateInput was populated because switching selectedOption
      answer({nextStep, status: selectedOption});
    }
  }

  function allowContinue() {
    return (
      selectedOption === "unchanged" ||
      (selectedOption === "changed" && startDateInput && !error)
    );
  }

  function verifyDateInput() {
    if (selectedOption === "changed") {
      const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD format to compare with the date input
      error = null;
      warning = null;
      if (financeAgreementStartDate === startDateInput) {
        error =
          "Please enter a different date from the original one you provided";
      } else if (startDateInput > today) {
        error = "Are you sure? This date is in the future";
      }
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      handleContinue();
    }
  }

  const startDateId = domId("start-date");
</script>

<div
  class="h-full flex flex-col gap-4"
  onkeydown={handleKeyDown}
  role="link"
  tabindex="0"
>
  <div class:hidden={financeAgreementStartDate === null} class="flex flex-col gap-4">
    <div>
      Did you finance this vehicle on <b>{localizeDate(financeAgreementStartDate)}</b>?
    </div>

    <RadioGroup
      name="finance_date[status]"
      options={[
        ["Yes", "unchanged"],
        ["No", "changed"]
      ]}
      bind:group={selectedOption}
    />
  </div>

  <div class:hidden={selectedOption !== "changed"} class="flex flex-col gap-1">
    <label for={startDateId} class="inline-block font-medium"
      >What was the start date of your finance agreement?</label
    >
    <input
      id={startDateId}
      type="date"
      name="finance_date[start_date]"
      class="input input-bordered w-full max-w-xs"
      disabled={selectedOption !== "changed"}
      bind:value={startDateInput}
    />
  </div>
  {#if error}
    <div class="bg-error-200 text-center rounded p-2">{error}</div>
  {/if}
</div>

<FloatingFooter>
  <button type="button" class="btn btn-ghost" onclick={back}>Back</button>
  <button
    type="button"
    class="btn btn-primary"
    onclick={handleContinue}
    disabled={!selectedOption}>Continue</button
  >
</FloatingFooter>
