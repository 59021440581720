<script lang="ts">
import { reactiveLocation } from '../lib/railsUtil.svelte';
import Dropdown from './Dropdown.svelte';
import Form from './Form.svelte';

import House from "~icons/ph/house";
import HouseD from "~icons/ph/house-fill";
import Envelope from "~icons/ph/envelope-simple";
import EnvelopeD from "~icons/ph/envelope-simple-bold";
import Faq from "~icons/ph/question";
import FaqD from "~icons/ph/question-bold";
import User from "~icons/ph/user-circle";
import UserD from "~icons/ph/user-circle-bold";
import Dots from "~icons/ph/dots-three-bold";
import Door from "~icons/ph/door-open-fill";
import Team from "~icons/ph/users-three-fill";
import Shield from "~icons/ph/shield-check-bold";
import Out from "~icons/ph/arrow-square-out-bold";
import Newspaper from "~icons/ph/newspaper-fill";
import { anyForCaseType, type todoIds } from '../lib/todoIds';

let {
  links,
  unread_messages,
  unread_news,
  todo_ids,
  case_type_id
} : {
  links: { [key: string]: [title: string, path: string, linkAttrs: {[key: string]: any}] };
  unread_messages: { [key: string]: string | null };
  unread_news: { [key: string]: number };
  todo_ids: todoIds;
  case_type_id: string;
} = $props()

const hasUnread = (key)=>{
  switch(key){
    case "messages":
      return Object.keys(unread_messages).length > 0;
    case "news":
      return Object.keys(unread_news).length > 0;
    case "home":
      return anyForCaseType(todo_ids, case_type_id);
    default:
      return false;
  }
}
const currentLocation = reactiveLocation()
const isCurrent = (key, path)=>{
  if(key == "home") return path == currentLocation.pathname;
  return currentLocation.pathname.startsWith(path);
}
const icons = {
  "home": {IconDesktop: HouseD, IconMobile: House},
  "messages": {IconDesktop: EnvelopeD, IconMobile: Envelope},
  "faqs": {IconDesktop: FaqD, IconMobile: Faq},
  "account": {IconDesktop: UserD, IconMobile: User},
  "news": {IconDesktop: Newspaper, IconMobile: Newspaper},
  "team": {IconDesktop: Team, IconMobile: Team},
  "logout": {IconDesktop: Door, IconMobile: Door},
  "privacy": {IconDesktop: Shield, IconMobile: Shield},
}
</script>

<ul class="
  gap-1 grid-cols-5 grid h-full w-full
  lg:gap-0.5 lg:flex lg:flex-col
">
  {@render navLink("home")}
  {@render navLink("messages")}
  {@render navLink("faqs")}
  {@render navLink("account")}
  <Dropdown class="nav-link-box lg:hidden" position="end top" margin="mb-1 -mr-1.5">
    {#snippet button()}
      <Dots class="w-8 h-auto pt-0.5 text-base-200 mx-auto" />
      {#if hasUnread("news")}<span class="notification-dot !top-[0.125rem] !left-1/2 ml-2 !right-auto"></span>{/if}
    {/snippet}
    {@render moreLinks()}
  </Dropdown>
  <div class="hidden lg:contents">
    <div class="flex-grow"></div>
    {@render moreLinks()}
  </div>
</ul>

{#snippet navLink(key)}
  {@const [title, path, linkAttrs] = links[key]}
  {@const {class:linkClass, ...attrs} = (linkAttrs || {})}
  {@const {IconDesktop, IconMobile} = icons[key]}
  {@const disabled = path == null}
  {@const active = !disabled && isCurrent(key, path.replace(/\?.*$/, ""))}
  <li class="nav-link-box" class:active={active}>
    <a href={path}
      class:lg:bg-base-200={active}
      class:lg:border-blue={active}
      class:pointer-events-none={disabled}
      class:opacity-25={disabled}
      class="
        text-white hover:text-white hover:bg-transparent {linkClass||''}
        max-lg:items-center max-lg:flex max-lg:flex-col max-lg:justify-center max-lg:p-0 max-lg:h-full
        border-transparent lg-nav-link
      " {title} {...attrs}>
      <span class="icon-container">
        <IconMobile class="w-7 h-7 lg:hidden"/>
        <IconDesktop class="w-6 h-6 hidden lg:block" />
        {#if hasUnread(key)}<span class="notification-dot"></span>{/if}
      </span>
      <span class="hidden lg:contents">{title}</span>
    </a>
  </li>
{/snippet}

{#snippet moreLink(key)}
  {#if links[key]}
    {@const [title, path, linkAttrs] = links[key]}
    {@const {class:linkClass, ...attrs} = (linkAttrs || {})}
    {@const {IconDesktop, IconMobile} = icons[key]}
    {@const active = isCurrent(key, path.replace(/\?.*$/, ""))}
    <li><a href={path}
      class:lg:bg-base-200={active}
      class:lg:border-blue={active}
      class="
        no-underline text-blue font-normal lg:hover:bg-base-200 {linkClass||''}
        border-transparent lg-nav-link
      " {title} {...attrs}>
      <span class="icon-container -ml-1 mr-1">
        <IconMobile class="w-7 h-7 lg:hidden" />
        <IconDesktop class="w-6 h-6 hidden lg:block" />
        {#if hasUnread(key)}<span class="notification-dot"></span>{/if}
      </span>
      {title}
      {#if attrs.target == "_blank"}<Out class="w-4 h-4 inline-block -mt-0.5" />{/if}
    </a></li>
  {/if}
{/snippet}

{#snippet moreLinks()}
  {@render moreLink("team")}
  {@render moreLink("news")}
  {@render moreLink("privacy")}
  <li class="mx-1.5 my-1"></li>
  <li>
    <button data-viewer-mode class="text-blue font-normal border-transparent lg-nav-link" form="logout-form">
      <span class="icon-container -ml-1 mr-1"><Door class="w-7 h-7 lg:w-6 lg:h-6" /></span>
      {links.logout[0]}
    </button>
    <Form action={links.logout[1]} method="delete" class="hidden" id="logout-form" />
  </li>
{/snippet}

<style lang="postcss">
.notification-dot {
  @apply absolute bg-warning rounded-full w-2.5 h-2.5 -top-0.5 -right-1;
}
.nav-link-box {
  @apply max-lg:relative max-lg:rounded-full max-lg:block;
  &.active { background: rgba(255,255,255,0.2); }
}
.lg-nav-link {
  @apply lg:py-3 lg:rounded-none;
  @apply lg:font-medium lg:text-base lg:text-blue lg:hover:text-blue lg:hover:bg-base-200;
  @apply lg:border-0 lg:border-solid lg:border-l-4;
}
.icon-container {
  @apply relative block lg:-ml-1 lg:mr-1;
}
</style>
