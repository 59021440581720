export type todoIds = { [key: string]: string[] }

export function anyForCaseType(todoIds : todoIds, caseTypeId : string) {
  return Object.values(todoIds).some(v=>v.includes(caseTypeId));
}

export function countForCaseType(todoIds : todoIds, caseTypeId : string) {
  return Object.values(todoIds).filter(v=>v.includes(caseTypeId)).length;
}

export function countForOtherCaseTypes(todoIds : todoIds, caseTypeId) {
  return Object.values(todoIds).filter(v=>v.length > 1 || !v.includes(caseTypeId)).length;
}