<script lang="ts">
import Dropdown from './Dropdown.svelte';
import Caret from "~icons/ph/caret-down-fill";
import Check from "~icons/ph/check-bold";
import Switch from "~icons/ph/user-switch";
import { countForCaseType, countForOtherCaseTypes, type todoIds } from '../lib/todoIds';

let {
  case_types,
  todo_ids,
  account_display_name,
  account_first_name,
  session_path,
  enable_account_switching
} : {
  case_types: {id: string, label: string, path: string, current: boolean}[];
  todo_ids: { [key: string]: string };
  account_display_name: string;
  account_first_name: string;
  session_path: string;
  enable_account_switching: bool;
} = $props()

let caseType = $derived(case_types.find(({current})=>current));
let otherTodos = $derived(countForOtherCaseTypes(todo_ids, caseType.id))
</script>

{#snippet title()}
  <h1 id="account_dropdown" class="font-normal text-base leading-tight whitespace-nowrap text-white">
    <b class="font-medium text-xs sm:text-base sm:font-normal block sm:inline">{account_first_name}'s</b>
    {" "}{caseType.label}
  </h1>
{/snippet}

{#if case_types.length > 1 || enable_account_switching }
  <Dropdown position="end bottom" margin="mt-1 -mr-2.5">
    {#snippet button()}
      <div class="flex items-center gap-2">
        {@render title()}
        <div class="btn btn-xs btn-circle bg-white" class:todo={!!otherTodos} data-count={otherTodos}>
          <Caret class="w-4 h-4 text-blue" />
        </div>
      </div>
    {/snippet}
    <li class="menu-title">{account_display_name}</li>
    {#each case_types as caseType}
      {@const todos = countForCaseType(todo_ids, caseType.id)}
      <li><a href={caseType.path} class="no-underline leading-relaxed">
        {#if caseType.current}
          <Check class="w-4 h-4 -ml-2 -mt-0.5 block" />
        {:else}
          <span class="w-4 h-4 -ml-2 -mt-0.5 block"></span>
        {/if}
        {caseType.label}
        {#if todos}
          <span class="rounded-full w-5 h-5 text-xs font-extrabold inline-flex items-center justify-center bg-warning">{todos}</span>
        {/if}
      </a></li>
    {/each}
    
    {#if enable_account_switching}
      <li class="my-2"></li>
      
      <li>
        <a href="{session_path}" class="no-underline leading-relaxed">
          <span class="w-4 h-4 -ml-2 -mt-0.5 block">
            <Switch class="w-5 h-5 -mx-0.5 -mt-0.5" />
          </span>
          Switch account
        </a>
      </li>
    {/if}
  </Dropdown>
{:else}
  {@render title()}
{/if}

<style lang="postcss">
  .todo {
    @apply outline-2 outline outline-warning;
    &::after {
      content: attr(data-count);
      @apply rounded-full w-5 h-5 text-xs font-extrabold flex items-center justify-center bg-warning absolute -right-2.5 -top-2.5;
    }
  }
</style>