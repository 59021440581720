import { getQueue } from "../../lib/PromiseQueue";
import { fetchRailsData } from "../../lib/railsUtil.svelte";
import XhrUpload from "../../lib/XhrUpload";
import type { FinalizedUpload, SharepointUploadInit, UploadError, UploadSession } from "./types";
import { Phase, type PhaseValue } from "./types";

const DELETED = Symbol("DELETED") // ProgressUI never sees DELETED, so Phase doesn't include it
const queue = getQueue("sharepoint")

class SharepointUpload {
  // Internal
  file: File = null;
  name: string
  createUploadSessionUrl: string = null;
  _uploadStart = $state<number>(null);
  _cleanup?: Function
  get filename(){ return this.file.name }
  
  // For ProgressUI
  progress = $state<number>(0); // 0-1
  phase = $state<PhaseValue | typeof DELETED>(Phase.WAITING);
  msecRemaining = $derived((this.progress > 0.1 && this._uploadStart) ? (1 - this.progress) / this.progress * (Date.now() - this._uploadStart) : null)
  deleteFn = $derived(this.phase == Phase.COMPLETE ? ()=>this.cancel() : null)
  cancelFn = $derived([Phase.COMPLETE, Phase.CANCELING, Phase.DELETING].includes(this.phase) ? null : ()=>this.cancel())
  retryFn = $derived(this.phase == Phase.FAILED ? ()=>this.start()  : null)

  // For SharepointFileInput
  deleted = $derived<boolean>(this.phase == DELETED);
  complete = $derived<boolean>(this.phase == Phase.COMPLETE);
  finalValue = $state<string>(null);

  constructor(init: SharepointUploadInit){
    this.file = init.file
    this.name = init.name
    // Optional to make it easier to test state derivation, not that I have written those tests
    this.createUploadSessionUrl = init.createUploadSessionUrl || null
    if(!this.createUploadSessionUrl) return;
    this.start();
  }

  async start(){
    try {
      this.checkpoint(Phase.WAITING)
      let uploadSession: UploadSession | UploadError = await queue.add(async ()=>{
        this.checkpoint(Phase.PREPARING)
        return fetchRailsData(this.createUploadSessionUrl, {
          method: "POST",
          body: JSON.stringify({
            name: this.name,
            filename: this.filename,
            checksum: await checksum(this.file),
            byte_size: this.file.size
          })
        }, "json")
      })
      const errorMessage = (uploadSession as UploadError).error || null;
      if(errorMessage) {
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      uploadSession = uploadSession as UploadSession;
      const directUpload = new XhrUpload(uploadSession.upload_url, this.file)
      directUpload.onProgress = (e: ProgressEvent)=>this.progress = (e.lengthComputable && e.total > 0) ? e.loaded / e.total : 0
      this._cleanup = ()=>directUpload.cancel()
      this.checkpoint(Phase.UPLOADING)
      this._uploadStart = Date.now()
      await directUpload.send()

      this.checkpoint(Phase.FINALIZING)
      const finalized: FinalizedUpload = await queue.add(()=>{
        this.checkpoint()
        return fetchRailsData(uploadSession.complete_url, {method: "POST"}, "json")
      })
      
      this.checkpoint(Phase.COMPLETE)
      this.finalValue = finalized.blob_id
      this._cleanup = ()=>fetchRailsData(finalized.delete_url, {method: "DELETE"}, "text")
    } catch(e) {
      if(!(e instanceof UserCanceled)) {
        this.phase = Phase.FAILED
        await this.cleanup()
        throw e;
      }
    }
  }

  async enqueue(fn) {
    return queue.add(fn);
  }

  checkpoint(phase = null) {
    if([Phase.CANCELING, Phase.DELETING, DELETED].includes(this.phase)) throw new UserCanceled();
    if(phase) this.phase = phase;
  }

  async cancel(){
    this.phase = this.phase == Phase.COMPLETE ? Phase.DELETING : Phase.CANCELING
    await this.cleanup()
    this.phase = DELETED
  }

  async cleanup(){
    this._uploadStart = null
    this.progress = 0
    if(this._cleanup){
      await this._cleanup()
      this._cleanup = null
    }
  }
}
export default SharepointUpload

async function checksum(file: File) {
  const buffer = await file.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
}

class UserCanceled extends Error {}