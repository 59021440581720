<script lang="ts">
  import { domId } from "../../../lib/dom";
  import FloatingFooter from "../../utils/FloatingFooter.svelte";
  import { type Component } from "svelte";

  let {
    answer,
    back,
    clientAddress,
    financeAgreementStartDateLocalized,
    nextStep,
  }: {
    answer: ({ nextStep }) => void;
    back: () => void;
    clientAddress: { [key: string]: string };
    financeAgreementStartDateLocalized: string;
    nextStep: Component;
  } = $props();

  let streetInput = $state(clientAddress.street);
  let cityInput = $state(clientAddress.city);
  let zipInput = $state(clientAddress.zip);
  let errors = $state([]);

  function handleContinue() {
    confirmNameInput();
    if (errors.length === 0) {
      answer({ nextStep });
    }
  }

  function confirmNameInput() {
    errors = [];
    if (streetInput === "") {
      errors.push("Street name & number is required");
    }
    if (cityInput === "") {
      errors.push("Town/City is required");
    }
    if (zipInput === "") {
      errors.push("Postal code is required");
    }
    if (
      clientAddress.street === streetInput &&
      clientAddress.city === cityInput &&
      clientAddress.zip === zipInput
    ) {
      errors.push(
        "Please enter a different address from the original one you provided",
      );
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      handleContinue();
    }
  }

  const streetId = domId("street");
  const cityId = domId("city");
  const zipId = domId("zip");
</script>

<div
  class="h-full flex flex-col gap-4"
  onkeydown={handleKeyDown}
  role="link"
  tabindex="0"
>
  <div>
    At which address did you live on <b>{financeAgreementStartDateLocalized}</b>?
  </div>

  <div class="flex flex-col gap-1">
    <label for={streetId} class="inline-block font-medium">
      Street Name & Number
    </label>
    <input
      id={streetId}
      type="text"
      name="client_address[street]"
      class="input input-bordered w-full"
      bind:value={streetInput}
    />
  </div>

  <div class="flex flex-col gap-1">
    <label for={cityId} class="inline-block font-medium"> Town/City </label>
    <input
      id={cityId}
      type="text"
      name="client_address[city]"
      class="input input-bordered w-full"
      bind:value={cityInput}
    />
  </div>

  <div class="flex flex-col gap-1">
    <label for={zipId} class="inline-block font-medium"> Postal Code </label>
    <input
      id={zipId}
      type="text"
      name="client_address[zip]"
      class="input input-bordered w-full"
      bind:value={zipInput}
    />
  </div>

  {#if errors.length > 0}
    <div class="bg-error-200 text-center rounded p-2">{errors.join(". ")}</div>
  {/if}
</div>

<FloatingFooter>
  <button type="button" class="btn btn-ghost" onclick={back}>Back</button>
  <button type="button" class="btn btn-primary" onclick={handleContinue}>Continue</button>
</FloatingFooter>
