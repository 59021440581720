<script lang="ts">
import type { InputUIError, SharepointFileInputProps } from "./FileInput/types";
import SharepointUpload from "./FileInput/SharepointUpload.svelte";
import revert from "../stores/revert";
import InputUI from "./FileInput/InputUI.svelte";
import ProgressUI from "./FileInput/ProgressUI.svelte";

const maxMB = 60

let {
  name, accept, createUploadSessionUrl, required, multiple
} : SharepointFileInputProps = $props();

let uploads = $state<SharepointUpload[]>([])
let visibleUploads = $derived(uploads.filter(u => !u.deleted))
let completeUploads = $derived(uploads.filter(u => u.complete))
let hasFiles = $derived(visibleUploads.length > 0)
let fileError = revert<InputUIError>(false, 6_000)
const cancelAll = () => visibleUploads.map(u => u.cancel())

export {
  hasFiles,
  uploadComplete,
  cancelAll
}

function addFiles(fileList: FileList) {
  // error {type: "count"} can't happen right now, but in the future we might support a max number of files
  if(!multiple && (fileList.length + visibleUploads.length > 1)) return $fileError = {type: "count", detail: 1};
  const newFiles = []
  const extensions = accept.map(t=>t.toLowerCase())
  for(const file of fileList) {
    const ext = file.name?.split(".")?.pop()?.toLowerCase()
    if(!extensions.includes(ext)) return $fileError = {type: "type"};
    if(file.size > maxMB * 1_024 * 1_024) return $fileError = {type: "size", detail: `${maxMB} MB`};
    newFiles.push(file)
  }
  uploads = [...uploads, ...newFiles.map(file => new SharepointUpload({name, file, createUploadSessionUrl}))]
}

function uploadComplete() {
  return visibleUploads.every(u => u.complete);
}
</script>
<InputUI {accept} {required} {multiple} error={$fileError} {hasFiles} {addFiles}>
  {#each visibleUploads as upload (upload)}
    <ProgressUI
      filename={upload.filename}
      phase={upload.phase}
      progress={upload.progress * 100}
      msecRemaining={upload.msecRemaining}
      cancel={upload.cancelFn}
      delete={upload.deleteFn}
      retry={upload.retryFn}
    />
  {/each}
  {#each completeUploads as upload}
    <input type="hidden" name="{name}{multiple ? "[]" : ""}" value={upload.finalValue} />
  {/each}
</InputUI>