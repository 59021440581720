<script lang="ts">
  import type { Snippet } from 'svelte';

  let {
    justify = "justify-between",
    children
  }: {
    justify?: string;
    children: Snippet;
  } = $props();
</script>

<div class="sticky bottom-0 left-0 right-0">
  <div class="absolute -top-7 -bottom-6 w-full -z-10 gradient-bg"></div>
  <div class="flex {justify}">
    {@render children()}
  </div>
</div>

<style>
  .gradient-bg {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 80%,
      rgba(255, 255, 255, 0) 100%
    );
  }
</style>
