<script lang="ts">
  import ListChecks from "~icons/ph/list-checks";
  import { countForCaseType, type todoIds } from "../lib/todoIds";
  let {
    mobile = false,
    case_type_id,
    todo_ids
  } : {
    mobile?: boolean;
    case_type_id: string;
    todo_ids: todoIds;
  } = $props()

  let count = $derived(countForCaseType(todo_ids, case_type_id))
  let any = $derived(count > 0)
</script>
{#if mobile}
  Tasks To Do<span
    class="text-black rounded-full flex items-center justify-center w-7 h-7 ml-2 pointer-events-none"
    class:bg-warning={any}
    class:bg-base-200={!any}
  >{count}</span>
{:else if any}
  <div class="alert alert-warning bg-warning-200 border-l-4 border-0 border-solid border-warning hidden lg:grid justify-between">
    <ListChecks class="w-6 h-6" />
    <span>Tasks To Do</span>
    <span class="rounded-full bg-white font-medium text-lg h-8 w-8 flex justify-center items-center">{count}</span>
  </div>
{/if}