const LOCALIZE_DEFAULT = {
  month: "long",
  day: "numeric",
  year: "numeric",
} as const
export function localizeDate(dateInput: string, options: Intl.DateTimeFormatOptions = LOCALIZE_DEFAULT) {
  if (!dateInput) {
    return;
  }

  const dateOnly = dateInput.match(/^\d{4}-\d{2}-\d{2}$/); // YYYY-MM-DD
  let date: Date;

  if (dateOnly === null) {
    date = new Date(dateInput);
  } else {
    date = new Date(dateInput + "T00:00:00");
  }

  return new Intl.DateTimeFormat(undefined, Object.assign({}, LOCALIZE_DEFAULT, options)).format(date);
}
